import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class AuthorService{
    async getAuthors(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let Authors = await axiosService.get(CONSTANTS.APIURL + `authors-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return Authors;
    }
    async getAuthorById(id) {
        let Authors = await axiosService.get(CONSTANTS.APIURL + `author?id=${id}` );
        return Authors;
    }
    async addAuthor(...author) {
        return await axiosService.post(CONSTANTS.APIURL + 'authors', ...author);
    }
    async updateAuthor(...author) {
        return await axiosService.put(CONSTANTS.APIURL +  'authors', ...author);
    }
    async deleteAuthor(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`authors?id=${id}`);
    }
    async getAuthor() {
        return await axiosService.get(CONSTANTS.APIURL +`authors`);
    }
}
export default new AuthorService();